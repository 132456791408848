import styled from 'styled-components';

export const FileSection = styled.div`
  height: 40px;
  gap: 0;
  background: #f1f5fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;

  .icon {
    width: 30px;
    height: 30px;
    top: 5px;
    left: 5px;
    padding: 6px 7.6px 6px 8px;
    gap: 0px;
    border-radius: 5px 5px 5px 5px;
    opacity: 0px;
    background: #858dbd;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-description {
    margin-left: 7px;
    font-family: General Sans;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
    text-align: left;
    color: #858dbd;
    align-self: center;
  }
`;
