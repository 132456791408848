import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';
import { updateSubscription } from '@queries/subscriptions/updateSubscription';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TSubscription } from 'app/types/entities/TSubscription';
import { BillingAccountService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 480px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
  }

  p.description {
    color: #00122d;
    margin: 30px 0;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

const RangeSliderWrapper = styled.div`
  input[type='range'] {
    height: 4px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 #000000;
    background: #e4ebf5;
    border-radius: 3px;
    border: 0 solid #010101;
  }

  input[type='range']::-webkit-slider-thumb {
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #e4ebf5;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 #000000;
    background: #e4ebf5;
    border-radius: 3px;
    border: 0 solid #010101;
  }

  input[type='range']::-moz-range-thumb {
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: #e4ebf5;
    border: 0 solid #010101;
    border-radius: 6px;
    box-shadow: 0 0 0 #000000;
  }

  input[type='range']::-ms-fill-upper {
    background: #e4ebf5;
    border: 0 solid #010101;
    border-radius: 6px;
    box-shadow: 0 0 0 #000000;
  }

  input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0 0 2px #454545;
    border: 4px solid #ffffff;
    height: 19px;
    width: 19px;
    border-radius: 19px;
    background: #b4b9d9;
    cursor: pointer;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: #e4ebf5;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: #e4ebf5;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  billingAccountId: string;
  subscription: TSubscription;
};

export const EditASOneSubscriptionModal = ({ onCancel, onSuccess, billingAccountId, subscription }: TProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [price, setPrice] = useState(0);
  const assignedCount = subscription?.licenses.filter((lic) => lic.assigneeId !== null).length;
  const totalCount = subscription?.licenses.length;
  const minLicensesCount = assignedCount > 1 ? assignedCount : 1;
  const [numberOfLicenses, setNumberOfLicenses] = useState(totalCount);
  const [freeSeats, setFreeSeats] = useState(0);

  useEffect(() => {
    (async () => {
      const plans = await call(BillingAccountService.getAllAsOnePlans());
      const plan = plans.find((item: any) => item.id === subscription.productId);
      setPrice(Number(plan?.price) ?? 0);
      if (plan) {
        setFreeSeats(plan?.freeSeats);
      }
    })();
  }, []);

  const innerSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);
    try {
      const result = await updateSubscription(billingAccountId, subscription.id, numberOfLicenses);
      if (!result || !result.success) {
        let message = 'Something went wrong. Please contact administrator.';
        if (result.error === 'No payment methods found') {
          message = 'Cannot create subscription. Please add the payment method first';
        }
        if (result.error === 'Subscription already exists') {
          message = 'You already have this subscription. Please choose another or modify an existing subscription';
        }
        onCancel();
        openModal(ModalNamesEnum.GenericErrorModal, {
          message,
        });
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
      onSuccess();
    } catch (err) {
      onCancel();
      openModal(ModalNamesEnum.GenericErrorModal, {
        message: 'Something went wrong. Please contact administrator.',
      });
    }
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 data-testid="purchasePlan">Confirm</h2>
      <p className="description">You are about to change the number of licenses for this subscription</p>

      <RangeSliderWrapper>
        <label htmlFor="steps-range" className="block mb-2 font-[600] text-xs text-primary text-left">
          Number of licenses:
        </label>
        <input
          id="steps-range"
          type="range"
          min={minLicensesCount}
          max={50}
          value={numberOfLicenses}
          step={1}
          onChange={(e) => setNumberOfLicenses(Number(e.target.value))}
          className="w-full h-2 bg-[#E4EBF5] rounded-lg appearance-none cursor-pointer"
        />
        <span className="text-xs">
          {numberOfLicenses} license{numberOfLicenses > 1 && 's'}
        </span>
      </RangeSliderWrapper>

      <div className="text-left my-4 flex flex-col">
        <div>
          <span className="font-[600] text-xs text-primary">Free licenses in subscription plan:</span>
          <span className="float-right">{freeSeats}</span>
        </div>
        <div>
          <span className="font-[600] text-xs text-primary">Total subscription price per month:</span>
          <span className="float-right">
            ${numberOfLicenses <= freeSeats ? 0 : (Number(price) * (numberOfLicenses - freeSeats)).toFixed(2)}
          </span>
        </div>
      </div>

      <div className="buttonContainer">
        <Button type="button" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-testid="submitButton"
          type="submit"
          onClick={innerSubmit}
          variant="contained"
          disabled={isSubmitting}
        >
          Update
        </Button>
      </div>
    </ModalWrapper>
  );
};
