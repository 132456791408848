import styled from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  gap: 60px;

  .left-block {
    flex-basis: 100%;
    .logo {
      flex: 0 0 auto;
      padding: 0 0 20px;
    }
    .social {
      display: flex;
      gap: 15px;
      padding: 0 0 40px;
    }
    .copyright {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #40608f;
    }
  }

  .menu {
    a {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #40608f;
      display: block;
      white-space: nowrap;
      padding: 0 0 10px;

      &:hover {
        text-decoration: underline;
      }
    }
    .last {
      padding: 58px 0 0;
    }
  }

  .contacts {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #40608f;
    white-space: nowrap;
    a {
      color: #40608f;
      display: block;
      white-space: nowrap;
      padding: 0 0 10px;

      &:hover {
        text-decoration: underline;
      }
    }
    .last {
      padding: 35px 0 0;
    }
  }
`;
