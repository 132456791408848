import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { useInitContext } from '@providers/InitProvider';
import { Initialization } from '@wrappers/Main/Initialization';

import { LandingPageFooter } from './components/Footer';
import { LandingPageMobileFooter } from './components/MobileFooter';
import { LandingPageHeader } from './components/Header';
import { LandingPageMobileHeader } from './components/MobileHeader';

import { Wrapper } from './styles/Wrapper';
import empowering from './images/empowering.jpg';

export const LandingPage = () => {
  const navigate = useNavigate();

  const openDashboard: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate('/dashboard');
  };
  const goToRegister: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate(routes.signup.make());
  };

  const { isAuthenticated, isInitialized } = useInitContext();

  if (!isInitialized) {
    return <Initialization />;
  }

  return (
    <Wrapper>
      <LandingPageHeader />
      <LandingPageMobileHeader />

      <main>
        <section className="top">
          <h1>Revolutionize Transcript Production With AutoScript</h1>
          <h3>
            Boost Productivity and Profitability with the Industry’s Leading Court Reporting and Transcription Tools
          </h3>
          {!isAuthenticated ? (
            <button className="primary-button" onClick={goToRegister}>
              Register & Get Started for Free
            </button>
          ) : (
            <button className="primary-button" onClick={openDashboard}>
              Open Dashboard
            </button>
          )}
        </section>

        <section className="blocks">
          <div className="block">
            <div className="block-title">AutoScript Speech-to-Text</div>
            <div className="block-content">
              Generate accurate draft transcripts with the industry’s most accurate automated speech recognition
              platform
            </div>
          </div>
          <div className="block">
            <div className="block-title">Powerful Transcript Editor</div>
            <div className="block-content">
              Edit and proof transcripts effortlessly with our free, easy to use AutoScript Desktop Editor or your
              favorite CAT software
            </div>
          </div>
          <div className="block">
            <div className="block-title">AutoScriptOne – Realtime Reporter</div>
            <div className="block-content">
              Capture, edit and produce client-ready roughs in real-time during live proceedings
            </div>
          </div>
          <div className="block">
            <div className="block-title">AutoScript Hub</div>
            <div className="block-content">
              Efficiently collaborate with colleagues or manage small to large agency teams at no additional cost
            </div>
          </div>
        </section>

        <section className="empowering">
          <img src={empowering} alt="" />
        </section>

        <section className="empowering-title">Work Smarter, Not Harder - With AutoScript</section>
        <section className="empowering-blocks">
          <div className="empowering-block empowering-block-1">
            <div className="block-title">Built for Legal Professionals</div>
            <div className="block-content">
              Our tools are specifically designed to meet the unique needs of the legal industry
            </div>
          </div>
          <div className="empowering-block empowering-block-2">
            <div className="block-title">Enhanced Productivity</div>
            <div className="block-content">Automate and expedite transcription tasks to save valuable time</div>
          </div>
          <div className="empowering-block empowering-block-3">
            <div className="block-title">High Accuracy</div>
            <div className="block-content">Leverage advanced speech recognition optimized for legal terminology</div>
          </div>
          <div className="empowering-block empowering-block-4">
            <div className="block-title">Secure and Confidential</div>
            <div className="block-content">Your data is secure with top-tier encryption and legal compliance</div>
          </div>
        </section>
      </main>

      <LandingPageFooter />
      <LandingPageMobileFooter />
    </Wrapper>
  );
};
