import styled from 'styled-components';
import { CloseIcon } from '@components/Modal/WhatsNew/icons/Close';

const ModalWrapper = styled.div`
  background: #e4ebf5;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 0;
  max-width: 800px;
  text-align: left;
  color: #40608f;

  h2 {
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.01em;
    text-align: left;
    padding: 15px 30px;
    border-radius: 5px 5px 0 0;
  }

  .close-icon {
    position: absolute;
    top: 17px;
    right: 30px;
    cursor: pointer;
  }

  .content {
    position: relative;
    padding: 30px 30px 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

type TProps = {
  onCancel: () => void;
};

export const OfflineModal = ({ onCancel }: TProps) => {
  return (
    <ModalWrapper>
      <h2>Offline</h2>
      <CloseIcon className="close-icon" onClick={onCancel} />

      <div className="content">Please check your internet connection</div>
    </ModalWrapper>
  );
};
