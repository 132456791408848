import styled from 'styled-components';

import emp1bg from '../images/empowering/p1.jpg';
import emp2bg from '../images/empowering/p2.jpg';
import emp3bg from '../images/empowering/p3.jpg';
import emp4bg from '../images/empowering/p4.jpg';

export const Wrapper = styled.div`
  max-width: 1440px;
  padding: 80px 70px 70px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;

  header {
    display: flex;
  }
  header.mobile {
    display: none;
  }

  footer {
    display: flex;
  }
  footer.mobile {
    display: none;
  }

  .primary-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    border: none;
    color: #fff;
    white-space: nowrap;
    &:hover {
      background: linear-gradient(90deg, #adc5e8 0%, #40608f 100%);
    }
  }

  h1 {
    color: #001a40;
    font-size: 70px;
    font-weight: 600;
    line-height: 91px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 80px 0 40px;
  }

  h3 {
    color: #858dbd;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0 0 40px;
  }

  section.top {
    padding: 0 0 140px;
    text-align: center;
    button {
      width: 280px;
      margin: 0 auto;
    }
  }

  section.blocks {
    display: flex;
    gap: 20px;
    padding: 0 0 40px;
    .block {
      background: #ffffff;
      padding: 20px;
      border-radius: 20px;
      min-width: 300px;
      .block-title {
        padding: 0 0 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #001a40;
      }
      .block-content {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #858dbd;
      }

      &:hover {
        background: #001a40;
        .block-title,
        .block-content {
          color: #f6f8f9;
        }
      }
    }
  }

  section.empowering {
    padding: 0 0 80px;
    img {
      width: 100%;
    }
  }

  section.empowering-title {
    color: #001a40;
    font-size: 50px;
    font-weight: 600;
    line-height: 65px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0 0 60px;
  }

  section.empowering-blocks {
    display: flex;
    gap: 20px;
    padding: 0 0 150px;
    .empowering-block-1 {
      background: url(${emp1bg}) no-repeat scroll left top transparent;
    }
    .empowering-block-2 {
      background: url(${emp2bg}) no-repeat scroll left top transparent;
    }
    .empowering-block-3 {
      background: url(${emp3bg}) no-repeat scroll left top transparent;
    }
    .empowering-block-4 {
      background: url(${emp4bg}) no-repeat scroll left top transparent;
    }

    .empowering-block {
      position: relative;
      padding: 20px;
      border-radius: 20px;
      flex: 0 0 auto;
      width: 310px;
      height: 500px;
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
      }
      .block-title {
        padding: 0 0 18px;
        font-size: 26px;
        font-weight: 600;
        line-height: 33.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #001a40;
      }
      .block-content {
        width: 290px;
        height: 110px;
        margin: 0 0 0 -10px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #001a40;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 15px;
      }
    }
  }

  @media (max-width: 1460px) {
    padding: 80px 35px 70px;

    section.blocks {
      flex-wrap: wrap;

      .block {
        max-width: calc(50% - 10px);
      }
    }
    section.empowering-blocks {
      flex-wrap: wrap;
      justify-content: center;
      width: 640px;
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    section.blocks {
      .block {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 730px) {
    padding: 80px 16px 50px;
    header {
      display: none;
    }
    header.mobile {
      display: flex;
    }
    section.empowering-blocks {
      width: 100%;

      .empowering-block {
        height: 320px;
        background-position: center center;
        .block-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
        .block-content {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          height: auto;
        }
      }
    }

    footer {
      display: none;
    }
    footer.mobile {
      display: flex;
    }
    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: 42px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    section.top {
      padding: 0 0 60px;
    }
    section.empowering-blocks {
      padding: 0 0 60px;
    }
    section.empowering-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 42px;
    }
    section.blocks .block .block-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    section.blocks .block .block-content {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;
