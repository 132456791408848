import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';

import { convertDuration } from '@helpers/convertDuration';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';

import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { REPORTING_TYPE_ENUM } from '@constants/enums/reportingTypes';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Move as MoveIcon } from '@components/icons/Move';
import { SortingDirection } from '@components/Table/SortingDirection';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { CustomTable } from '@components/Table';
import { StatusText } from '@components/Table/StatusText';
import { ASOne as ASOneIcon } from '@components/icons/ASOne';
import { Hamburger as HamburgerIcon } from '@components/icons/Hamburger';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { HistoryIcon } from '@components/icons/History';
import { SortableHead } from '@components/Table/SortableHead';
import { Tooltip } from '@components/Tooltip';

import { HamburgerButtons } from './HamburgerButtons';
import { JobStatusActionsButton } from './JobStatusActionsButton';
import { canShowHamburger } from './helpers/canShowHamburger';
import { TUser } from 'app/types/entities/TUser';
import { TJob } from 'app/types/entities/TJob';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { jobIsEditable } from '@helpers/jobStatus/jobIsEditable';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';
import { jobCanBeReassigned } from '@helpers/jobStatus/jobCanBeReassigned';
import { PagePagination } from '@components/Pagination';

const StyledMoveIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      stroke: #fff;
      color: #fff;
    }
  }
`;

const StyledIconLink = styled.a`
  width: 30px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  flex: 0 0 auto;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const StyledHamburgerIcon = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    circle {
      fill: #fff;
      color: #fff;
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 60px;
  gap: 10px;
  position: relative;
  overflow: visible !important;
`;

const StatusCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 80px;

  &.with-history {
    padding-right: 28px;
  }
`;

const StatusCellContent = styled.div`
  position: relative;

  p:first-child {
    font-size: 16px;
    line-height: 130%;
    margin: 10px 0 0;
  }
`;

const StatusDate = styled.p`
  color: #b4b9d9;
  margin: 0 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
`;

const HistoryButton = styled.div`
  margin: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;

  :hover {
    path {
      fill: #858dbd;
    }
  }
`;

const JobName = styled.div`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 10px 0 0;

  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #00122d;
  }
`;

const IdField = styled.p`
  margin: 0 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  color: #b4b9d9;
`;

const TagsField = styled.p`
  display: flex;
  gap: 5px;
  margin: 0 0 10px;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-align: left;
  color: #40608f;
  flex-wrap: wrap;

  * {
    white-space: unset !important;
    overflow: visible !important;
    text-overflow: unset !important;
  }

  &:empty {
    display: none;
  }
`;

const AssignCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible !important;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      margin: 10px 0 0;
      text-decoration: underline;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 160%;
      align-items: center;
      justify-content: center;
      margin: 0 0 10px;
      text-decoration: none;
    }
  }
`;

const DeadlineCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  overflow: visible !important;

  .deadLine {
    width: 95px;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
const AssignLink = styled(StyledLink)`
  text-decoration: none;
`;

type TProps = {
  jobs: TPaginatedResponse<TJob>;
  setJobs: (jobs: TPaginatedResponse<TJob>) => void;
  loadJobs: (
    page: number,
    pageSize: number,
    orderBy: string,
    orderType: string,
    search: string,
    repositoryId: string,
  ) => Promise<TPaginatedResponse<TJob>>;
  me?: TUser;
  setLoading: (v: boolean) => void;
  currentTab: string;
  entityId: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export const JobsTable = ({ jobs, setJobs, loadJobs, setLoading, currentTab, entityId, search, setSearch }: TProps) => {
  const { workspace, me, organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;

  const [sortingField, setSortingField] = useState<keyof TJob>('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [hiddenButtonsVisible, setHiddenButtonsVisible] = useState<string | null>(null);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);

  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;

  const canViewAllJobsHistory = organization?.permissions?.viewJobs === 'all';
  const canManageWorkspaces = organization?.permissions?.manageWorkspaces;
  const canSubmitJobs = organization?.permissions?.submitJobs;

  const reload = () => {
    setReloadPageTrigger(!reloadPageTrigger);
  };

  useEffect(() => {
    setJobs({ data: [], count: 0 });
    reload();
  }, [currentTab, workspace, organization]);

  useEffect(() => {
    (async () => {
      setJobs({ data: [], count: 0 });
      setLoading(true);
      const jobs = await loadJobs(
        page,
        pageSize,
        sortingField,
        sortingReverse ? 'desc' : 'asc',
        search,
        entityId ?? '',
      );
      setJobs(jobs);
      setLoading(false);
    })();
  }, [currentTab, workspace, organization, page, pageSize, search]);

  const showMoveJobToWSModal = (job: unknown) => {
    openModal(ModalNamesEnum.MoveJobToWSModal, {
      onSuccess: () => {
        setReloadPageTrigger(!reloadPageTrigger);
        closeModal();
      },
      job,
    });
  };

  const editAssignee = (job: TJob) => {
    openModal(ModalNamesEnum.AssignJobModal, {
      onSuccess: () => {
        setReloadPageTrigger(!reloadPageTrigger);
        closeModal();
      },
      job,
    });
  };

  const editDeadline = (job: TJob) => {
    openModal(ModalNamesEnum.EditDeadline, {
      onSuccess: () => {
        setReloadPageTrigger(!reloadPageTrigger);
        closeModal();
      },
      job,
    });
  };

  const handleShowHistory = (job: TJob) => {
    openModal(ModalNamesEnum.JobHistory, {
      jobId: job.id,
    });
  };

  const toggleSort = (field: keyof TJob) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState) => !prevState);
    }
  };

  const generateJobLink = (jobId: string, jobStatus: keyof typeof JOB_STATUSES_ENUM) => {
    if (jobIsEditable(jobStatus)) {
      return routes.jobEdit.make(organization?.slug ?? '', workspace?.slug ?? '', jobId);
    }

    return routes.jobDetails.make(organization?.slug ?? '', workspace?.slug ?? '', jobId);
  };

  const tableHead = [
    {
      headComponent: () => (
        <SortableHead onClick={() => toggleSort('name')}>
          Job Title
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TJob) => (
        <div>
          <JobName data-testid={`jobTitle${data.name}`}>
            <StyledLink to={generateJobLink(data.id, data.status)}>{data.name}</StyledLink>
          </JobName>
          <IdField>{data.id}</IdField>
          <TagsField>
            {(data.tags ?? []).map((tag, i) => {
              return (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setSearch(`#${tag}`);
                  }}
                  key={`${data.id}-${i}`}
                >
                  #{tag}
                </a>
              );
            })}
          </TagsField>
        </div>
      ),
    },
    {
      render: (data: TJob) => (
        <DeadlineCell>
          {!data.deadline && <p className="deadLine"></p>}
          {data.deadline && <p className="deadLine">{getAmericanTime(data.deadline, false)}</p>}
          {organization?.permissions?.submitJobs && <PencilIcon onClick={() => editDeadline(data)} />}
        </DeadlineCell>
      ),
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('deadline')}>
          Due
          {sortingField === 'deadline' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob, index: number) => {
        const showEditAssigneeButton = organization?.permissions?.reassignJobs && jobCanBeReassigned(data.status);
        const assignee = data?.assignee || data?.draftAssignee;
        return (
          <AssignCell>
            {!assignee ? <div className="assignee"></div> : null}
            {assignee ? (
              <Tooltip key={index} content={assignee?.email || ''} placement="bottom">
                <div className="assignee">
                  <AssignLink to={routes.userDetails.make(organization?.slug ?? '', assignee?.id)}>
                    <p className="assignName">
                      {assignee?.name} {assignee?.lastname}
                    </p>
                    <p className="assignEmail">{assignee?.email || ''}</p>
                  </AssignLink>
                </div>
              </Tooltip>
            ) : null}
            {showEditAssigneeButton ? <PencilIcon onClick={() => editAssignee(data)} /> : null}
          </AssignCell>
        );
      },
      headComponent: () => <td style={{ width: '1%' }}>ASSIGNEE</td>,
    },
    {
      render: (data: TJob) => <p>{getAmericanTime(data.createdAt)}</p>,
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' ? <SortingDirection reverse={sortingReverse} /> : null}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => (
        <p>
          {data.reportingType === REPORTING_TYPE_ENUM.REPORTING_TYPE_FTR
            ? 'To be analyzed'
            : convertDuration(data.duration)}
        </p>
      ),
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('duration')}>
          Duration
          {sortingField === 'duration' ? <SortingDirection reverse={sortingReverse} /> : null}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => {
        const showHistory = canViewAllJobsHistory || me.id === data.assignee?.id;
        const cellClass = showHistory ? 'with-history' : '';
        return (
          <StatusCell className={cellClass}>
            <StatusCellContent>
              <StatusText testid={`jobStatus${data.name}`} status={JOB_STATUSES_ENUM[data.status]} />
              <StatusDate>{formatDateMsDsYHI(data.statusChangeDate ?? '')}</StatusDate>
            </StatusCellContent>
            {showHistory ? (
              <HistoryButton onClick={() => handleShowHistory(data)}>
                <HistoryIcon />
              </HistoryButton>
            ) : null}
          </StatusCell>
        );
      },
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('status')}>
          Status
          {sortingField === 'status' ? <SortingDirection reverse={sortingReverse} /> : null}
        </SortableHead>
      ),
    },
    {
      render: (data: TJob) => <JobStatusActionsButton job={data} reload={reload} />,
      headComponent: () => <td style={{ width: '1%' }} />,
    },

    {
      render: (data: TJob) => {
        const showASOneButton = !data.asOneLocked && jobIsScheduled(data.status);

        const getASOneLinkPrefix = () => {
          return `${window.location.origin}/as-one/job/`;
        };

        return (
          <IconsWrapper>
            {canSubmitJobs ? (
              <StyledMoveIconButton title="Move to another Workspace" onClick={() => showMoveJobToWSModal(data)}>
                <MoveIcon />
              </StyledMoveIconButton>
            ) : null}
            {showASOneButton ? (
              <StyledIconLink
                title="Open in AutoScriptOne"
                href={`${getASOneLinkPrefix()}${data.meetingId}`}
                aria-disabled={false}
              >
                <ASOneIcon />
              </StyledIconLink>
            ) : null}
            {canShowHamburger(data, isVsAdmin, canManageWorkspaces, organization, me) ? (
              <>
                <StyledHamburgerIcon
                  title="Open additional actions"
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    setHiddenButtonsVisible(data.id);
                  }}
                >
                  <HamburgerIcon />
                </StyledHamburgerIcon>

                {hiddenButtonsVisible === data.id ? (
                  <HamburgerButtons
                    job={data}
                    reloadPageTrigger={reloadPageTrigger}
                    setReloadPageTrigger={setReloadPageTrigger}
                    setHiddenButtonsVisible={setHiddenButtonsVisible}
                  />
                ) : null}
              </>
            ) : null}
          </IconsWrapper>
        );
      },
      renderStyles: { width: '1%' },
      headComponent: () => <td style={{ width: '1%' }} />,
    },
  ];

  return (
    <>
      <CustomTable data={jobs.data} head={tableHead} />

      <PagePagination totalItemsCount={jobs?.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
    </>
  );
};
