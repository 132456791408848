import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { routes } from '@routes';
import { useInitContext } from '@providers/InitProvider';
// import { useAPI } from '@hooks/useAPI';
import { IconButton } from '@components/form-elements/buttons/IconButton';

import { Wrapper } from './styles/Wrapper';
import Logo from './icons/logo.svg';
import WhiteLogo from './icons/white-logo.svg';
import Hamburger from './icons/hamburger.svg';
import CrossIcon from './icons/cross.svg';

const StyledIcon = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;
`;

export const LandingPageMobileHeader = () => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  const openDashboard: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate('/dashboard');
  };
  const goToRegister: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.signup.make());
  };
  const goToContactSales: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.contactSales.make());
  };

  const { isAuthenticated } = useInitContext();

  return (
    <Wrapper className="mobile">
      <div className="left-side">
        <div className="logo">
          <RouterLink
            to="/"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <Logo />
          </RouterLink>
        </div>
      </div>
      <div className="right-side">
        <StyledIcon
          onClick={(e) => {
            e.preventDefault();
            setMenuVisible(true);
          }}
        >
          <Hamburger />
        </StyledIcon>
      </div>

      {menuVisible ? (
        <div className="opened">
          <div className="flex">
            <div className="left-side">
              <div className="logo">
                <RouterLink
                  to="/"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <WhiteLogo />
                </RouterLink>
              </div>
            </div>
            <div className="right-side">
              <StyledIcon
                onClick={(e) => {
                  e.preventDefault();
                  setMenuVisible(false);
                }}
              >
                <CrossIcon />
              </StyledIcon>
            </div>
          </div>

          <div className="menu">
            <button className="white-button" onClick={goToContactSales}>
              Contact Sales
            </button>
            {!isAuthenticated ? (
              <button className="primary-button" onClick={goToRegister}>
                Get Started
              </button>
            ) : (
              <button className="primary-button" onClick={openDashboard}>
                Dashboard
              </button>
            )}
          </div>
        </div>
      ) : null}
    </Wrapper>
  );
};
