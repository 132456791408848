import styled from 'styled-components';

export const Wrapper = styled.header`
  position: fixed;
  z-index: 999;
  background: #f9fafb;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 40px 16px 24px;

  .opened {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #001a40;
    padding: 40px 16px 24px;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .menu {
      display: flex;
      gap: 16px;
      padding: 20px 0 0;

      .white-button {
        border-color: #ffffff;
        color: #ffffff;
      }
    }
  }

  .primary-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    border: none;
    color: #fff;
    white-space: nowrap;
    &:hover {
      background: linear-gradient(90deg, #adc5e8 0%, #40608f 100%);
    }
  }

  .white-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    color: #858dbd;
    white-space: nowrap;
    border: 1px solid #d5def2;
    background: none;
    &:hover {
      border: 1px solid #2f5d87;
      color: #2f5d87;
    }
  }

  .link-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    background: none;
    border: none;
    color: #40608f;
    &:hover {
      text-decoration: underline;
    }
  }
`;
