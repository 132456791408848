import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useAPI } from '@hooks/useAPI';
import { SupportService } from 'app/API';

import { Button } from '@components/form-elements/buttons/Button';
import { Textarea } from '@components/form-elements/Textarea';
import { Input } from '@components/form-elements/Input';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { PositiveIcon } from '@components/icons/Positive';

import { Wrapper } from './styles/Wrapper';

export const ContactSalesForm = () => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('form');
  const [formState, setFormState] = useState({
    details: {
      name: '',
      surname: '',
      email: '',
      phone: '',
    },
    typeOfWork: '',
    companyName: '',
    role: '',
    tools: {
      asd: false,
      cat: false,
      eclipse: false,
      maxscribe: false,
      word: false,
      other: false,
      otherName: '',
    },
    ASaccount: false,
    outsource: '',
    outsourceTo: '',
    notes: '',
  });
  const submitDisabled =
    isLoading ||
    !formState.details.name ||
    !formState.details.surname ||
    !formState.details.email ||
    !formState.details.phone ||
    !formState.typeOfWork ||
    !formState.role ||
    !formState.outsource;

  const submit = async () => {
    window.scroll(0, 0);
    setIsLoading(true);
    await call(
      SupportService.sendSalesEmailFromLanding({
        requestBody: {
          name: formState.details.name,
          surname: formState.details.surname,
          email: formState.details.email,
          phone: formState.details.phone,
          typeOfWork: formState.typeOfWork,
          companyName: formState.companyName,
          role: formState.role,
          tools: formState.tools,
          ASaccount: formState.ASaccount,
          outsource: formState.outsource,
          outsourceTo: formState.outsourceTo,
          notes: formState.notes,
        },
      }),
    );
    setStep('success');
    setIsLoading(false);
  };

  if (step === 'success') {
    return (
      <Wrapper className="success">
        <PositiveIcon className="positiveIcon" />
        <h1>Great!</h1>
        <h3>Your message is sent.</h3>
        <p>
          Now you can <RouterLink to="/">go back</RouterLink> to the main page.
        </p>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h4>Personal details</h4>
      <div className="line">
        <Input
          onChange={(event) => {
            setFormState({
              ...formState,
              details: {
                ...formState.details,
                name: event.target.value,
              },
            });
          }}
          label="First name"
          value={formState.details.name}
          required={true}
        />
        <Input
          onChange={(event) => {
            setFormState({
              ...formState,
              details: {
                ...formState.details,
                surname: event.target.value,
              },
            });
          }}
          label="Last name"
          value={formState.details.surname}
          required={true}
        />
      </div>
      <div className="line">
        <Input
          onChange={(event) => {
            setFormState({
              ...formState,
              details: {
                ...formState.details,
                email: event.target.value,
              },
            });
          }}
          label="Email address"
          value={formState.details.email}
          required={true}
        />
        <Input
          onChange={(event) => {
            setFormState({
              ...formState,
              details: {
                ...formState.details,
                phone: event.target.value,
              },
            });
          }}
          label="Phone number"
          value={formState.details.phone}
          required={true}
        />
      </div>
      <h4>Type of work *</h4>
      <div className="line">
        <Button
          onClick={() => {
            if (formState.typeOfWork === 'independent') {
              setFormState({
                ...formState,
                typeOfWork: 'agency',
                companyName: '',
              });
            } else {
              setFormState({
                ...formState,
                typeOfWork: 'independent',
                companyName: '',
              });
            }
          }}
          className={`${formState.typeOfWork === 'independent' ? 'check-button active' : 'check-button'}`}
        >
          I am independent contractor
        </Button>
        <Button
          onClick={() => {
            if (formState.typeOfWork === 'agency') {
              setFormState({
                ...formState,
                typeOfWork: 'independent',
                companyName: '',
              });
            } else {
              setFormState({
                ...formState,
                typeOfWork: 'agency',
                companyName: '',
              });
            }
          }}
          className={`${formState.typeOfWork === 'agency' ? 'check-button active' : 'check-button'}`}
        >
          I work for an Agency / Company
        </Button>
      </div>
      {formState.typeOfWork === 'agency' ? (
        <div className="line">
          <Input
            onChange={(event) => {
              setFormState({
                ...formState,
                companyName: event.target.value,
              });
            }}
            label="Agency / Company name (Optional)"
            value={formState.companyName}
            required={false}
          />
        </div>
      ) : null}
      <h4>Your role *</h4>
      <div className="line">
        <Button
          onClick={() => {
            if (formState.role === 'owner') {
              setFormState({
                ...formState,
                role: '',
              });
            } else {
              setFormState({
                ...formState,
                role: 'owner',
              });
            }
          }}
          className={`${formState.role === 'owner' ? 'check-button active' : 'check-button'}`}
        >
          Owner / Operations
        </Button>
        <Button
          onClick={() => {
            if (formState.role === 'reporter') {
              setFormState({
                ...formState,
                role: '',
              });
            } else {
              setFormState({
                ...formState,
                role: 'reporter',
              });
            }
          }}
          className={`${formState.role === 'reporter' ? 'check-button active' : 'check-button'}`}
        >
          Reporter
        </Button>
        <Button
          onClick={() => {
            if (formState.role === 'transcriber') {
              setFormState({
                ...formState,
                role: '',
              });
            } else {
              setFormState({
                ...formState,
                role: 'transcriber',
              });
            }
          }}
          className={`${formState.role === 'transcriber' ? 'check-button active' : 'check-button'}`}
        >
          Transcriber / Editor
        </Button>
        <Button
          onClick={() => {
            if (formState.role === 'everything') {
              setFormState({
                ...formState,
                role: '',
              });
            } else {
              setFormState({
                ...formState,
                role: 'everything',
              });
            }
          }}
          className={`${formState.role === 'everything' ? 'check-button active' : 'check-button'}`}
        >
          I do everything
        </Button>
      </div>
      <h4>What tools do you use today?</h4>
      <div className="line">
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              tools: {
                ...formState.tools,
                asd: !formState.tools.asd,
              },
            });
          }}
          className={`${formState.tools.asd ? 'check-button active' : 'check-button'}`}
        >
          AutoScript Desktop
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              tools: {
                ...formState.tools,
                cat: !formState.tools.cat,
              },
            });
          }}
          className={`${formState.tools.cat ? 'check-button active' : 'check-button'}`}
        >
          Case CATalyst
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              tools: {
                ...formState.tools,
                eclipse: !formState.tools.eclipse,
              },
            });
          }}
          className={`${formState.tools.eclipse ? 'check-button active' : 'check-button'}`}
        >
          Eclipse
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              tools: {
                ...formState.tools,
                maxscribe: !formState.tools.maxscribe,
              },
            });
          }}
          className={`${formState.tools.maxscribe ? 'check-button active' : 'check-button'}`}
        >
          MAXScribe
        </Button>
      </div>
      <div className="line">
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              tools: {
                ...formState.tools,
                word: !formState.tools.word,
              },
            });
          }}
          className={`${formState.tools.word ? 'check-button active' : 'check-button'}`}
        >
          Word
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              tools: {
                ...formState.tools,
                other: !formState.tools.other,
                otherName: '',
              },
            });
          }}
          className={`${formState.tools.other ? 'check-button active' : 'check-button'}`}
        >
          Other (Specify)
        </Button>
      </div>
      {formState.tools.other ? (
        <div className="line">
          <Input
            onChange={(event) => {
              setFormState({
                ...formState,
                tools: {
                  ...formState.tools,
                  otherName: event.target.value,
                },
              });
            }}
            label="Type software names, dividing them with commas (Optional)"
            value={formState.tools.otherName}
            required={false}
          />
        </div>
      ) : null}
      <h4>Do you have an AutoScript account? *</h4>
      <div className="line">
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              ASaccount: true,
            });
          }}
          className={`${formState.ASaccount ? 'check-button active' : 'check-button'}`}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              ASaccount: false,
            });
          }}
          className={`${!formState.ASaccount ? 'check-button active' : 'check-button'}`}
        >
          No
        </Button>
      </div>
      <h4>Do you outsource your transcription? *</h4>
      <div className="line">
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              outsource: 'never',
              outsourceTo: '',
            });
          }}
          className={`${formState.outsource === 'never' ? 'check-button active' : 'check-button'}`}
        >
          Never
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              outsource: 'sometimes',
              outsourceTo: '',
            });
          }}
          className={`${formState.outsource === 'sometimes' ? 'check-button active' : 'check-button'}`}
        >
          Sometimes
        </Button>
        <Button
          onClick={() => {
            setFormState({
              ...formState,
              outsource: 'all',
              outsourceTo: '',
            });
          }}
          className={`${formState.outsource === 'all' ? 'check-button active' : 'check-button'}`}
        >
          All / Most of the time
        </Button>
      </div>

      {formState.outsource === 'sometimes' || formState.outsource === 'all' ? (
        <div className="line">
          <Input
            onChange={(event) => {
              setFormState({
                ...formState,
                outsourceTo: event.target.value,
              });
            }}
            label="What company do you outsource to? (Optional)"
            value={formState.outsourceTo}
            required={false}
          />
        </div>
      ) : null}

      <h4>Anything else? (optional)</h4>
      <div className="line">
        <Textarea
          onChange={(event) => {
            setFormState({
              ...formState,
              notes: event.target.value,
            });
          }}
          value={formState.notes}
          label="What else do you want to share with us?"
        />
      </div>

      <Button onClick={submit} className="primary" disabled={submitDisabled}>
        Submit
      </Button>
    </Wrapper>
  );
};
