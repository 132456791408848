import { Wrapper } from './styles/Wrapper';
import image from './images/image.png';

export const ContactSalesLeftSection = () => {
  return (
    <Wrapper>
      <h1>Contact AutoScript Team</h1>
      <h3>Our Product Specialists Are Ready to Assist You!</h3>
      <p>Boost your transcript production efficiency and create 2 to 3 times more transcript pages per hour.</p>
      <p>
        Fill out the form, and we’ll get back to you promptly to schedule a convenient time to help you start your
        journey toward greater productivity and profitability.
      </p>
      <img src={image} alt="" />
    </Wrapper>
  );
};
