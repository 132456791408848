import styled from 'styled-components';

export const Wrapper = styled.header`
  position: fixed;
  z-index: 999;
  padding: 40px 70px;
  background: #f9fafb;
  width: 100%;
  max-width: 1440px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  height: 50px;
  gap: 20px;
  align-items: center;

  @media (max-width: 1460px) {
    padding: 40px 35px;
  }

  @media (max-width: 660px) {
    padding: 40px 16px;
  }

  .primary-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    border: none;
    color: #fff;
    white-space: nowrap;
    &:hover {
      background: linear-gradient(90deg, #adc5e8 0%, #40608f 100%);
    }
  }

  .white-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    color: #858dbd;
    white-space: nowrap;
    border: 1px solid #d5def2;
    background: none;
    &:hover {
      border: 1px solid #2f5d87;
      color: #2f5d87;
    }
  }

  .link-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    background: none;
    border: none;
    color: #40608f;
    &:hover {
      text-decoration: underline;
    }
  }

  .left-side {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .logo {
      width: 150px;
      flex: 0 0 auto;
      img {
        width: 100%;
      }
    }
  }
  .right-side {
    display: flex;
    gap: 20px;
    align-items: center;
  }
`;
