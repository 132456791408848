export enum EJobPipelineStatuses {
  'not_started' = 'Not started',
  'preprocessor_started' = 'Preprocessing',
  'preprocessor_completed' = 'Preprocessing completed',
  'preprocessor_failed' = 'Preprocessing failed',

  'transcript_submitter_failed' = 'Transcribing error',
  'assembly_submitter_started' = 'Transcribing (Model 1)',
  'assembly_submitter_completed' = 'Transcribing completed (Model 1)',
  'assembly_submitter_failed' = 'Transcribing failed (Model 1)',
  'deepgram_submitter_started' = 'Transcribing (Model 2)',
  'deepgram_submitter_completed' = 'Transcribing completed (Model 2)',
  'deepgram_submitter_failed' = 'Transcribing failed (Model 2)',

  'transcript_preparer_started' = 'Transcript formatting',
  'transcript_preparer_completed' = 'Transcript formatting completed',
  'transcript_preparer_failed' = 'Transcript formatting failed',

  'OPEN_AI_started' = 'Open AI processing started',
  'OPEN_AI_failed' = 'Open AI processing failed',
  'OPEN_AI_completed' = 'Open AI processing completed',

  'WHISPER_started' = 'Whisper processing started',
  'WHISPER_failed' = 'Whisper processing failed',
  'WHISPER_completed' = 'Whisper processing completed',

  'DOLBY_API_started' = 'Dolby api processing started',
  'DOLBY_API_failed' = 'Dolby api processing failed',
  'DOLBY_API_completed' = 'Dolby api processing completed',

  'successfully_processed' = 'Completed',
}
