import React from 'react';

import { CustomTable } from '@components/Table';
import { PagePagination } from '@components/Pagination';
import { useJobsTableHead } from './hooks/useJobsTableHead';

import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TJob } from 'app/types/entities/TJob';

type TProps = {
  jobs: TPaginatedResponse<TJob>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  orderType: string;
  setOrderType: React.Dispatch<React.SetStateAction<string>>;
  reload: () => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  checkedJobs: string[];
  setCheckedJobs: (v: string[]) => void;
};

export const JobsTable = ({
  jobs,
  pageSize,
  setPageSize,
  orderBy,
  orderType,
  setOrderType,
  setOrderBy,
  reload,
  search,
  setSearch,
  checkedJobs,
  setCheckedJobs,
}: TProps) => {
  const { tableHead } = useJobsTableHead({
    orderBy,
    setOrderBy,
    orderType,
    setOrderType,
    reload,
    search,
    setSearch,
    jobs: jobs.data,
    checkedJobs,
    setCheckedJobs,
  });

  return (
    <>
      <CustomTable data={jobs.data} head={tableHead} />

      <PagePagination totalItemsCount={jobs?.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
    </>
  );
};
