import React, { useEffect, useState } from 'react';

import { useModalContext } from '@providers/ModalProvider';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useAuthContext } from '@providers/AuthProvider';
import { getHourPrice } from '@helpers/getHourPrice';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { AdminService, BillingAccountService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import ASOneDescriptionBlock from '../../ASOne/Blocks/ASOneDescriptionBlock';
import RSProDescriptionBlock from '../../ASOne/Blocks/RSProDescriptionBlock';
import { SubscriptionCard } from '../../ASOne/SubscriptionCard';

import { FreeSeatsDescription } from './styles/FreeSeatsDescription';
import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';
import { SubscriptionTypes } from './types/SubscriptionTypes';

export const AdminChooseASOneSubscriptionModal = ({ onCancel, onSuccess, billingAccountId }: TProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const { updateMe } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionType] = useState<SubscriptionTypes>('AutoScriptOne');
  const [allPlans, setAllPlans] = useState<TSubscriptionProduct[]>([]);
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setPlans([]);
      const billingAccount = await call(AdminService.getBillingAccount({ baId: billingAccountId }));
      setBillingAccount(billingAccount);
      const allPlans = await call(BillingAccountService.getAllAsOnePlans());
      setAllPlans(allPlans);
      setPlans(allPlans.filter((plan: any) => plan.subscriptionProductCategory === subscriptionType));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setPlans(allPlans.filter((plan) => plan.subscriptionProductCategory === subscriptionType));
  }, [subscriptionType]);

  const showContactSalesModal = () => {
    openModal(ModalNamesEnum.ContactSales);
  };

  if (!billingAccount?.id) {
    return <></>;
  }

  const selectPlan = async (id: string) => {
    await updateMe();
    const plan = plans.find((item) => item.id === id);

    onSuccess({
      subscriptionProductId: id,
      planName: plan?.name ?? '',
      price: plan?.price ?? 0,
      freeSeats: plan?.freeSeats || 0,
    });
  };

  const activeIds = (billingAccount?.subscriptions ?? [])
    .filter((sub) => {
      return sub.status === 'active' || sub.status === 'trialing';
    })
    .map((sub) => {
      return sub.productId;
    });
  const activePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) > -1;
  });
  const inactivePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) === -1;
  });

  const cardTitle = subscriptionType === 'AutoScriptOne' ? 'AutoScript One' : 'Reporter Studio Pro';

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 className="cardTitle">{cardTitle} Pricing Plans</h2>

      <div className="scrollContainer">
        <div className="cardContainer">
          {activePlans.map((item) => {
            const descriptions = item.description.split('<newLine>');
            return (
              <div key={item.id} className="card">
                <SubscriptionCard
                  title={item.name.replace('AutoScriptOne ', '')}
                  subTitle={getHourPrice(item.capacityInSeconds, item.price)}
                  descriptions={descriptions}
                  verticalDescription
                  buttonLabel="Choose Plan"
                  buttonVariant="text"
                  chosen={true}
                  buttonSize="medium"
                  buttonOnClick={() => selectPlan(item.id)}
                />
              </div>
            );
          })}
          {inactivePlans.map((item) => {
            let descriptions: (string | React.ReactNode)[] = item.description.split('<newLine>');
            if (item.freeSeats) {
              descriptions = [
                <FreeSeatsDescription key={item.id}>
                  For <b>BETA</b> users, first {item.freeSeats} licenses are free for 2024. You only pay for the
                  Realtime speech to text per your plan rate.
                </FreeSeatsDescription>,
              ];
            }
            return (
              <div key={item.id} className={`card ${item.freeSeats ? 'free-seats' : ''}`}>
                <SubscriptionCard
                  title={item.name.replace('AutoScriptOne ', '')}
                  subTitle={getHourPrice(item.capacityInSeconds, item.price)}
                  descriptions={descriptions}
                  verticalDescription
                  buttonLabel="Choose Plan"
                  buttonVariant="text"
                  chosen={false}
                  buttonSize="medium"
                  buttonOnClick={() => selectPlan(item.id)}
                />
              </div>
            );
          })}
        </div>
      </div>

      {subscriptionType === 'AutoScriptOne' ? <ASOneDescriptionBlock /> : <RSProDescriptionBlock />}

      <div className="buttonModalContainer">
        <Button variant="bold" onClick={showContactSalesModal}>
          Contact Our Sales Team
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Wrapper>
  );
};
