import { TRoutes } from './types/TRoutes';

const byId = (pattern = '', id = '') => pattern.replace(':id', id);
const byAOneId = (pattern = '', aOneId = '') => pattern.replace(':aOneId', aOneId);
const byUserId = (pattern = '', userId = '') => pattern.replace(':userId', userId);
const byJobId = (pattern = '', jobId = '') => pattern.replace(':jobId', jobId);
const byWsslug = (pattern = '', wsSlug = '') => pattern.replace(':wsSlug', wsSlug);
const byOrgSlug = (pattern = '', orgSlug = '') => pattern.replace(':orgSlug', orgSlug);
const byTeamSlug = (pattern = '', teamSlug = '') => pattern.replace(':teamSlug', teamSlug);
const byRepoSlug = (pattern = '', repoSlug = '') => pattern.replace(':repoSlug', repoSlug);

export const routes: TRoutes = {
  root: {
    pattern: '/',
    make() {
      return this.pattern;
    },
  },

  contactSales: {
    pattern: '/contact-sales',
    make() {
      return this.pattern;
    },
  },

  paymentSuccess: {
    pattern: '/payment/success',
    make() {
      return this.pattern;
    },
  },
  paymentFailure: {
    pattern: '/payment/failure',
    make() {
      return this.pattern;
    },
  },

  adminDiff: {
    pattern: '/admin/diff',
    make() {
      return this.pattern;
    },
  },

  adminPrompts: {
    pattern: '/admin/prompts',
    make() {
      return this.pattern;
    },
  },

  adminDashboard: {
    pattern: '/admin/dashboard',
    make() {
      return this.pattern;
    },
  },

  notifications: {
    pattern: '/notifications',
    make() {
      return this.pattern;
    },
  },

  notificationSettings: {
    pattern: '/notifications/settings',
    make() {
      return this.pattern;
    },
  },

  adminWorkspacesList: {
    pattern: '/admin/workspaces',
    make() {
      return this.pattern;
    },
  },

  adminWorkspaceDetails: {
    pattern: '/admin/workspaces/:id',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminRepositoryDetails: {
    pattern: '/admin/repository/:id',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminTeamDetails: {
    pattern: '/admin/team/:id',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminSettings: {
    pattern: '/admin/settings',
    make() {
      return this.pattern;
    },
  },

  adminOrganizationsList: {
    pattern: '/admin/organizations',
    make() {
      return this.pattern;
    },
  },

  adminOrganizationDetails: {
    pattern: '/admin/organizations/:id',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminAdminsList: {
    pattern: '/admin/admins',
    make() {
      return this.pattern;
    },
  },

  adminCanvasCoursesList: {
    pattern: '/admin/canvas/courses',
    make() {
      return this.pattern;
    },
  },

  adminCanvasCourseStudentsPage: {
    pattern: '/admin/canvas/courses/:id/students',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminOrganizationUsersList: {
    pattern: '/admin/organizations/:id/members',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminOrganizationInvitationsList: {
    pattern: '/admin/organizations/:id/invitations',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminUsersList: {
    pattern: '/admin/users',
    make() {
      return this.pattern;
    },
  },

  adminBillingAccountsList: {
    pattern: '/admin/billing-accounts',
    make() {
      return this.pattern;
    },
  },

  adminJobsReport: {
    pattern: '/admin/jobs-report',
    make() {
      return this.pattern;
    },
  },

  adminUserDetails: {
    pattern: '/admin/users/:userId',
    make(userId = '') {
      return byUserId(this.pattern, userId);
    },
  },

  adminJobDetails: {
    pattern: '/admin/job-details/:id',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminJobUtterances: {
    pattern: '/admin/job-details/:id/utterances',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },

  adminSSOProvidersList: {
    pattern: '/admin/sso-providers',
    make() {
      return this.pattern;
    },
  },

  adminSubscriptions: {
    pattern: '/admin/subscriptions',
    make() {
      return this.pattern;
    },
  },

  adminTransactions: {
    pattern: '/admin/transactions',
    make() {
      return this.pattern;
    },
  },

  adminSMSCountries: {
    pattern: '/admin/sms-countries',
    make() {
      return this.pattern;
    },
  },

  adminSMSWhitelist: {
    pattern: '/admin/sms-whitelist',
    make() {
      return this.pattern;
    },
  },

  adminFeatureFlags: {
    pattern: '/admin/feature-flags',
    make() {
      return this.pattern;
    },
  },

  adminBillingAccountDetails: {
    pattern: '/admin/billing-account/:id',
    make(billingAccountId = '') {
      return byId(this.pattern, billingAccountId);
    },
  },

  adminAutoScriptOneSubscriptionDetails: {
    pattern: '/admin/subscription/:aOneId',
    make(aOneId = '') {
      return byAOneId(this.pattern, aOneId);
    },
  },

  adminTransactionCodesList: {
    pattern: '/admin/transaction-codes',
    make() {
      return this.pattern;
    },
  },

  shareJob: {
    pattern: '/share/job/:jobId',
    make(jobId = '') {
      return byJobId(this.pattern, jobId);
    },
  },

  openInASOne: {
    pattern: '/as-one/job/:id',
    make(recordingSessionId = '') {
      return byId(this.pattern, recordingSessionId);
    },
  },

  noOrgs: {
    pattern: '/create-organization',
    make() {
      return this.pattern;
    },
  },

  accountPage: {
    pattern: '/account',
    make() {
      return this.pattern;
    },
  },

  desktopAppHelp: {
    pattern: '/desktop-app',
    make() {
      return this.pattern;
    },
  },

  signin: {
    pattern: '/signin',
    make() {
      return this.pattern;
    },
  },
  accountDisabled: {
    pattern: '/account-disabled',
    make() {
      return this.pattern;
    },
  },
  signup: {
    pattern: '/signup',
    make() {
      return this.pattern;
    },
  },

  ssoSignin: {
    pattern: '/sso/login',
    make() {
      return this.pattern;
    },
  },

  passwordRecovery: {
    pattern: '/password-recovery',
    make() {
      return this.pattern;
    },
  },
  passwordReset: {
    pattern: '/password-reset',
    make() {
      return this.pattern;
    },
  },
  registerSuccess: {
    pattern: '/register-success',
    make() {
      return this.pattern;
    },
  },
  registerTokenExpired: {
    pattern: '/register-token-expired',
    make() {
      return this.pattern;
    },
  },
  billingAccount: {
    pattern: '/:orgSlug/billing-account',
    make(orgSlug = '') {
      return byOrgSlug(this.pattern, orgSlug);
    },
  },

  autoScriptOneSubscriptionDetails: {
    pattern: '/:orgSlug/billing-account/subscription/:aOneId',
    make(orgSlug = '', aOneId = '') {
      return byAOneId(byOrgSlug(this.pattern, orgSlug), aOneId);
    },
  },

  organizationsList: {
    pattern: '/organizations',
    make() {
      return this.pattern;
    },
  },

  dashboard: {
    pattern: '/:orgSlug/dashboard',
    make(orgSlug = '') {
      return byOrgSlug(this.pattern, orgSlug);
    },
  },
  organizationDetails: {
    pattern: '/:orgSlug/details',
    make(slug = '') {
      return byOrgSlug(this.pattern, slug);
    },
  },
  organizationMembers: {
    pattern: '/:orgSlug/members',
    make(slug = '') {
      return byOrgSlug(this.pattern, slug);
    },
  },
  organizationTeams: {
    pattern: '/:orgSlug/teams',
    make(slug = '') {
      return byOrgSlug(this.pattern, slug);
    },
  },
  organizationMemberReports: {
    pattern: '/:orgSlug/member-reports',
    make(slug = '') {
      return byOrgSlug(this.pattern, slug);
    },
  },
  newRepository: {
    pattern: '/:orgSlug/case/new',
    make(slug = '') {
      return byOrgSlug(this.pattern, slug);
    },
  },
  repositoryDetails: {
    pattern: '/:orgSlug/case/:repoSlug',
    make(orgSlug = '', repoSlug = '') {
      return byOrgSlug(byRepoSlug(this.pattern, repoSlug), orgSlug);
    },
  },
  repositoriesList: {
    pattern: '/:orgSlug/cases',
    make(orgSlug = '') {
      return byOrgSlug(this.pattern, orgSlug);
    },
  },
  userDetails: {
    pattern: '/:orgSlug/members/:userId',
    make(orgSlug = '', userId = '') {
      return byOrgSlug(byUserId(this.pattern, userId), orgSlug);
    },
  },
  invitations: {
    pattern: '/:orgSlug/invitations',
    make(orgSlug = '') {
      return byOrgSlug(this.pattern, orgSlug);
    },
  },
  allJobs: {
    pattern: '/:orgSlug/all-jobs',
    make(orgSlug = '') {
      return byOrgSlug(this.pattern, orgSlug);
    },
  },

  workspaceDetails: {
    pattern: '/:orgSlug/workspaces/:wsSlug/details',
    make(orgSlug = '', wsSlug = '') {
      return byOrgSlug(byWsslug(this.pattern, wsSlug), orgSlug);
    },
  },
  newJob: {
    pattern: '/:orgSlug/workspaces/:wsSlug/jobs/new',
    make(orgSlug = '', wsSlug = '') {
      return byOrgSlug(byWsslug(this.pattern, wsSlug), orgSlug);
    },
  },
  jobEdit: {
    pattern: '/:orgSlug/workspaces/:wsSlug/jobs/edit/:id',
    make(orgSlug = '', wsSlug = '', id = '') {
      return byOrgSlug(byWsslug(byId(this.pattern, id), wsSlug), orgSlug);
    },
  },
  jobDetails: {
    pattern: '/:orgSlug/workspaces/:wsSlug/jobs/details/:id',
    make(orgSlug = '', wsSlug = '', id = '') {
      return byOrgSlug(byWsslug(byId(this.pattern, id), wsSlug), orgSlug);
    },
  },
  userJobsReport: {
    pattern: '/:orgSlug/workspaces/:wsSlug/job-reports/:userId',
    make(orgSlug = '', wsSlug = '', userId = '') {
      return byOrgSlug(byWsslug(byUserId(this.pattern, userId), wsSlug), orgSlug);
    },
  },
  jobsReport: {
    pattern: '/:orgSlug/workspaces/:wsSlug/job-reports',
    make(orgSlug = '', wsSlug = '') {
      return byOrgSlug(byWsslug(this.pattern, wsSlug), orgSlug);
    },
  },

  requestAccess: {
    pattern: '/:orgSlug/workspaces/:wsSlug/requestAccess',
    make(orgSlug = '', wsSlug = '') {
      return byOrgSlug(byWsslug(this.pattern, wsSlug), orgSlug);
    },
  },
  acceptInvitation: {
    pattern: '/invitation/:id/accept',
    make(id = '') {
      return byId(this.pattern, id);
    },
  },
  internalError: {
    pattern: '/errors/500',
    make() {
      return this.pattern;
    },
  },

  jobsList: {
    pattern: '/:orgSlug/workspaces/:wsSlug/jobs',
    make(orgSlug = '', wsSlug = '') {
      return byOrgSlug(byWsslug(this.pattern, wsSlug), orgSlug);
    },
  },

  teamDetails: {
    pattern: '/:orgSlug/team/:teamSlug',
    make(orgSlug = '', teamSlug = '') {
      return byOrgSlug(byTeamSlug(this.pattern, teamSlug), orgSlug);
    },
  },
};
