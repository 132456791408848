import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 30px 90px;
  background: #fff;
  border-radius: 20px;
  width: 640px;
  position: relative;
  @media (max-width: 1455px) {
    width: 100%;
  }

  &.success {
    text-align: center;
    height: 430px;
    padding: 30px;

    svg {
      margin: 50px auto;
      width: 100px;
      height: 100px;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #40608f;
      padding: 0 0 30px;
    }
    h3 {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
      color: #858dbd;
      padding: 0 0 30px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      color: #40608f;
      a {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        text-decoration: underline;
        color: #40608f;
      }
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #001a40;
    padding: 0 0 20px;
  }

  .line {
    display: flex;
    gap: 20px;
    padding: 0 0 20px;

    button {
      white-space: nowrap;
    }
    .form-input {
    }
  }

  .form-input {
    input {
      color: #40608f;
      border: 1px solid #cedaeb;
      background: #f8fafb;
      box-shadow: none;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #001a40;
        color: #001a40;
      }
    }
  }

  .textarea-wrapper {
    textarea {
      color: #40608f;
      border: 1px solid #cedaeb;
      background: #f8fafb;
      box-shadow: none;
      height: 100px;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #001a40;
        color: #001a40;
      }
    }
  }

  .check-button {
    height: 40px;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid #40608f;
    background: transparent;
    color: #40608f;

    &:hover {
      border: 1px solid #001a40;
      color: #001a40;
    }

    &.active {
      background: #858dbd;
      border: 1px solid #858dbd;
      color: #ffffff;
    }
  }

  button.primary {
    width: 130px;
    height: 30px;
    //background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    //border: none;
    //color: #ffffff;
    position: absolute;
    bottom: 30px;
    right: 30px;
    &:hover {
      //background: linear-gradient(90deg, #adc5e8 0%, #40608f 100%);
    }
  }
`;
