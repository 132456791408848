import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { useInitContext } from '@providers/InitProvider';
// import { useAPI } from '@hooks/useAPI';
import { Wrapper } from './styles/Wrapper';
import logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

export const LandingPageHeader = () => {
  const navigate = useNavigate();

  const openDashboard: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate('/dashboard');
  };
  const goToLogin: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.signin.make());
  };
  const goToRegister: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.signup.make());
  };
  const goToContactSales: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    window.scroll(0, 0);
    navigate(routes.contactSales.make());
  };

  const { isAuthenticated } = useInitContext();

  return (
    <Wrapper>
      <div className="left-side">
        <div className="logo">
          <RouterLink
            to="/"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <img src={logo} alt="" />
          </RouterLink>
        </div>
      </div>

      <div className="right-side">
        {!isAuthenticated ? (
          <button className="link-button" onClick={goToLogin}>
            Log In
          </button>
        ) : null}
        <button className="white-button" onClick={goToContactSales}>
          Contact Sales
        </button>
        {!isAuthenticated ? (
          <button className="primary-button" onClick={goToRegister}>
            Get Started
          </button>
        ) : (
          <button className="primary-button" onClick={openDashboard}>
            Dashboard
          </button>
        )}
      </div>
    </Wrapper>
  );
};
