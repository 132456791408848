import { useInitContext } from '@providers/InitProvider';
import { Initialization } from '@wrappers/Main/Initialization';

import { LandingPageFooter } from './components/Footer';
import { LandingPageHeader } from './components/Header';

import { Wrapper } from './styles/Wrapper';
import { ContactSalesLeftSection } from '@pages/ContactSales/components/LeftSection';
import { ContactSalesForm } from '@pages/ContactSales/components/Form';

export const ContactSalesPage = () => {
  const { isInitialized } = useInitContext();

  if (!isInitialized) {
    return <Initialization />;
  }

  return (
    <Wrapper>
      <LandingPageHeader />

      <main>
        <ContactSalesLeftSection />
        <ContactSalesForm />
      </main>

      <LandingPageFooter />
    </Wrapper>
  );
};
