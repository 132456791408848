import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 640px;
  @media (max-width: 1455px) {
    width: 100%;
    padding: 0 0 40px;
  }

  h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 65px;
    text-align: left;
    color: #001a40;
    padding: 0 0 20px;
  }
  h3 {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
    color: #001a40;
    padding: 0 0 20px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #40608f;
    padding: 0 0 20px;
  }
  img {
    padding: 20px 0 0;
    @media (max-width: 1455px) {
      display: none;
    }
  }
`;
