import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #00122d;

  & h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-transform: capitalize;
    color: #858dbd;
    margin-top: 0;
    padding: 0 0 20px;
  }

  .title {
    text-align: left;
    color: #858dbd;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    padding: 0 0 10px;
  }

  .title-value {
    text-align: left;
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 26px;
    display: flex;
    gap: 15px;
    align-items: center;

    svg {
      cursor: pointer;
    }

    svg:hover {
      path {
        fill: #40608f;
      }
    }

    &.balance-refresh svg {
      animation: rotation 0.4s infinite linear;
      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
    }
  }

  .select {
    background: #f8fafb;
    height: 30px;
    margin: 0 0 20px;
  }

  .buttonWrapper {
    display: flex;
    grid-gap: 10px;
    margin-top: 30px;
  }

  .errorsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.active {
      padding-top: 20px;
    }

    .page-error {
      margin: 0;
    }
  }

  .text-end {
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    color: #00122d;
    line-height: 40px;
  }

  .description-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    white-space: nowrap;
    color: #00122d;
    margin: 0;
  }

  .dflex {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    border-top: 1px solid #d5ddf2;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;

    & span {
      font-weight: 600;
      font-size: 12px;
      color: #858dbd;
    }
  }

  & .noBorder {
    margin-top: 20px;
    border: none;
  }
`;
