import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type TProps = {
  children: ReactNode;
};

type TState = {
  isOffline: boolean;
};

const OfflineContext = createContext<TState>({
  isOffline: true,
});

export const OfflineProvider = ({ children }: TProps) => {
  const [offLine, setOffLine] = useState(false);

  useEffect(() => {
    const onOnLine = () => {
      console.log('[OfflineProvider] Online');
      setOffLine(false);
    };
    const onOffLine = () => {
      console.log('[OfflineProvider] Offline');
      setOffLine(true);
    };
    window.addEventListener('offline', onOffLine);
    window.addEventListener('online', onOnLine);
    return () => {
      window.removeEventListener('offline', onOffLine);
      window.removeEventListener('online', onOnLine);
    };
  }, []);

  return (
    <OfflineContext.Provider
      value={{
        isOffline: offLine,
      }}
    >
      {children}
    </OfflineContext.Provider>
  );
};

export const useOfflineContext = () => useContext(OfflineContext);
