import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { v4 } from 'uuid';

import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { TJob } from 'app/types/entities/TJob';

import { useAuthContext } from '@providers/AuthProvider';

import { REPORTING_TYPE_ENUM } from '@constants/enums/reportingTypes';
import { TFile } from 'app/types/entities/TFile';
import { fileDataFormat } from '@pages/User/NewJobPage/helpers/fileDataFormat';
import { TUser } from 'app/types/entities/TUser';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { useAPI } from '@hooks/useAPI';
import { type GetManyForUserResponseDto, JobsService, LayoutsService, MediaService } from 'app/API';
import { routes } from '@routes';
import { useStateWithPromise } from '@hooks/useStateWithPromise';

type TForm = {
  item?: TJob;
  disabled: boolean;
};

type TFormItemValue = string | boolean | number | null | TUser | Date | string[] | undefined;

type TState = {
  isNew: boolean;
  jobId: string;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  isFilesLoading: boolean;
  setIsFilesLoading: (v: boolean) => void;
  isFilesUploading: boolean;
  setIsFilesUploading: (v: boolean) => void;
  form: TForm;
  setForm: (v: TForm) => void;
  setFormItemValue: (n: string, v: TFormItemValue) => void;
  filesFromServer: TFileWithMeta[];
  setFilesFromServer: (v: TFileWithMeta[]) => void;
  newFiles: TFileWithMeta[];
  setNewFiles: (v: TFileWithMeta[]) => void;
  filesCount: number;
  setFilesCount: (v: number) => void;
  allFiles: TFileWithMeta[];
  setAllFiles: (v: TFileWithMeta[]) => void;
  onRemoveFile: (v: TFileWithMeta) => void;
  layouts: GetManyForUserResponseDto[] | undefined;
};

const PageContext = createContext<TState>({
  isNew: true,
  jobId: '',
  isLoading: false,
  setIsLoading: () => {},
  isFilesLoading: false,
  setIsFilesLoading: () => {},
  isFilesUploading: false,
  setIsFilesUploading: () => {},
  form: {
    disabled: true,
  },
  setForm: () => {},
  setFormItemValue: () => {},
  filesFromServer: [],
  setFilesFromServer: () => {},
  newFiles: [],
  setNewFiles: () => {},
  filesCount: 0,
  setFilesCount: () => {},
  allFiles: [],
  setAllFiles: () => {},
  onRemoveFile: () => {},
  layouts: [],
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const navigate = useNavigate();
  const params = useParams() as { id?: string };
  const { workspace, organization } = useAuthContext();

  const [layouts, setLayouts] = useState<GetManyForUserResponseDto[] | undefined>([]);
  const [filesFromServer, setFilesFromServer] = useState<TFileWithMeta[]>([]);
  const [newFiles, setNewFiles] = useStateWithPromise<TFileWithMeta[]>([]);
  const [filesCount, setFilesCount] = useState(0);
  const [allFiles, setAllFiles] = useState<TFileWithMeta[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilesLoading, setIsFilesLoading] = useState(false);
  const [isFilesUploading, setIsFilesUploading] = useState(false);

  const isNew = !params?.id;
  const [jobId] = useState(params.id ?? v4());

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [form, setForm] = useState<TForm>({
    item: {
      id: jobId,
      name: '',
      dualChannel: false,
      verbatim: false,
      multipleFilesHandling: 'concat',
      meetingTime: '12:00',
      meetingTimezone: tz,
      createdTimestamp: Date.now().toString(),
      creatorId: '',
      ownerEmail: '',
      duration: 0,
      numOfSpeakers: 0,
      media: [],
      parts: [],
      attachments: [],
      reportingType: REPORTING_TYPE_ENUM.REPORTING_TYPE_FTR,
      status: 'JOB_STATUS_DRAFT',
      removed: false,
      createdAt: new Date(),
      notes: '',
      refunded: false,
      checkoutEvents: [],
      layoutId: null,
      partialUnlocked: false,
    },
    disabled: true,
  });

  useEffect(() => {
    setForm({
      ...form,
      disabled: !form.item?.name,
    });
  }, [form.item]);

  useEffect(() => {
    (async () => {
      if (!workspace?.id || !organization?.id) {
        setIsLoading(true);
        return;
      }

      const layouts = await call(LayoutsService.getOrganizationLayouts({ oid: organization.id }));
      setLayouts(layouts);

      if (isNew) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      setIsFilesLoading(true);

      const [jobData, files] = await Promise.all([
        call(JobsService.getJobById({ id: jobId, wsid: workspace.id })),
        call(MediaService.getFilesList({ jobId })),
      ]);
      const newList = (files.media || []).map((item: TFile) => fileDataFormat(item));
      setFilesFromServer(newList);
      setIsLoading(false);
      setIsFilesLoading(false);
      if (jobData) {
        if (jobData.asOneLocked) {
          navigate(routes.jobsList.make(organization?.slug ?? '', workspace.slug));
          return;
        }
        setForm({
          ...form,
          item: jobData,
        });
      }
    })();
  }, [jobId, isNew, workspace?.id]);

  const onRemoveFile = (file: TFileWithMeta) => {
    const removeFilter = (f: TFileWithMeta) => f.meta.Id !== file.meta.Id;
    setFilesFromServer([...filesFromServer.filter(removeFilter)]);
    setNewFiles([...newFiles.filter(removeFilter)]);
  };

  useEffect(() => {
    setAllFiles(
      [...filesFromServer, ...newFiles]
        .map((f, idx) => {
          return { ...f, weight: idx };
        })
        .filter((f) => f.meta?.status !== 'removed'),
    );
  }, [filesFromServer, newFiles]);

  useEffect(() => {
    // const nonLoadedStatuses = [
    //   'uploading',
    //   'started',
    //   'getting_upload_params',
    //   'calculating_duration',
    //   'pre_calculating_duration',
    // ];
    const file = allFiles.find((item) => item.meta?.status !== 'done');
    setIsFilesUploading(!!file);
  }, [allFiles]);

  const setFormItemValue = (name: string, value: TFormItemValue) => {
    setForm({
      ...form,
      item: {
        ...form.item,
        [name]: value,
      } as TJob,
    });
  };

  return (
    <PageContext.Provider
      value={{
        isNew,
        jobId,
        isLoading,
        setIsLoading,
        isFilesLoading,
        setIsFilesLoading,
        isFilesUploading,
        setIsFilesUploading,
        form,
        setForm,
        filesFromServer,
        setFilesFromServer,
        newFiles,
        setNewFiles,
        setFormItemValue,
        filesCount,
        setFilesCount,
        allFiles,
        setAllFiles,
        onRemoveFile,
        layouts,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
