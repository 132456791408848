import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { UniversalNoPictureCard } from '@components/UniversalNoPictureCard';
import { useAuthContext } from '@providers/AuthProvider';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { PaymentService } from '../../../../API';
import { useAPI } from '@hooks/useAPI';

type TProps = {
  billingAccount: TBillingAccount;
};

export const BillingAccountCard = ({ billingAccount }: TProps) => {
  const [paymentInfo, setPaymentInfo] = useState('...');
  const navigate = useNavigate();
  const { call } = useAPI();
  const { organization } = useAuthContext();

  useEffect(() => {
    if (!billingAccount.id) {
      return;
    }

    call(PaymentService.billingAccountPaymentMethodList({ baId: billingAccount.id })).then((paymentMethods?: any[]) => {
      // console.log('paymentMethods', paymentMethods);
      if (!paymentMethods || !paymentMethods.length) {
        setPaymentInfo('No payment method on file');
        return;
      }

      const defaultPaymentMethod = paymentMethods.filter((pm) => pm.default);

      if (defaultPaymentMethod.length > 0) {
        setPaymentInfo('Card **** **** **** ' + defaultPaymentMethod[0].last4);
      } else {
        setPaymentInfo('No payment method on file');
      }
    });
  }, [billingAccount.id]);

  const handleBillingAccountDetailsClick = () => {
    navigate(routes.billingAccount.make(organization?.slug ?? ''));
  };

  const content = (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          handleBillingAccountDetailsClick();
        }}
        href={routes.billingAccount.make(organization?.slug ?? '')}
        className="font-medium text-queen-blue hover:text-primary"
      >
        {billingAccount.name}
      </a>
      <p className="text-primary text-xs">{paymentInfo}</p>
      <p className="text-primary text-xs">&nbsp;</p>
    </>
  );

  const actions = [{ label: 'Details', onClick: handleBillingAccountDetailsClick }];

  return <UniversalNoPictureCard content={content} actions={actions} />;
};
