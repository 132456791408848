import styled from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  .logo {
    flex: 0 0 auto;
  }

  .social {
    display: flex;
    gap: 15px;
  }

  .menu {
    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #40608f;
      display: block;
      white-space: nowrap;
      padding: 0 0 10px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .terms-privacy-menu {
    display: flex;
    gap: 10px;
    color: #40608f;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #40608f;
      display: block;
      white-space: nowrap;
      padding: 0 0 10px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contacts {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #40608f;
    white-space: nowrap;
    text-align: center;
    a {
      color: #40608f;
      display: block;
      white-space: nowrap;
      padding: 0 0 10px;

      &:hover {
        text-decoration: underline;
      }
    }
    .last {
      padding: 35px 0 0;
    }
  }
  .copyright {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #40608f;
  }
`;
