import { Link as RouterLink } from 'react-router-dom';

import { Wrapper } from './styles/Wrapper';
import FooterLogo from './images/logo.svg';

import FbIcon from './images/facebook.svg';
import YtIcon from './images/youtube.svg';
import LiIcon from './images/linkedin.svg';

export const LandingPageFooter = () => {
  return (
    <Wrapper>
      <div className="left-block">
        <div className="logo">
          <FooterLogo alt="" />
        </div>

        <div className="social">
          <a href="https://www.linkedin.com/company/voicescript/" target="_blank" rel="noreferrer">
            <LiIcon alt="LinkedIn" />
          </a>
          <a href="https://www.facebook.com/voicescriptinc" target="_blank" rel="noreferrer">
            <FbIcon alt="Facebook" />
          </a>
          <a href="https://www.youtube.com/@voicescript8018" target="_blank" rel="noreferrer">
            <YtIcon alt="YouTube" />
          </a>
        </div>

        <div className="copyright">© {new Date().getFullYear()} VoiceScript, Inc. All rights reserved</div>
      </div>

      <div className="menu">
        <a href="https://voicescript.ai/" target="_blank" rel="noreferrer">
          VoiceScript.ai
        </a>
        <a href="https://thecourtreportingacademy.com/" target="_blank" rel="noreferrer">
          TCRA
        </a>
        <RouterLink className="last" to="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
          Terms of service
        </RouterLink>
      </div>

      <div className="contacts">
        <div>
          <a href="mailto:contact@voicescript.ai">contact@voicescript.ai</a>
        </div>
        <div>
          <a href="tel:+17726215205">(772) 621-5205</a>
        </div>
        <div>Cocoa, Florida 32926, US</div>
        <RouterLink className="last" to="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
          Privacy policy
        </RouterLink>
      </div>
    </Wrapper>
  );
};
