import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1440px;
  min-width: 960px;
  padding: 80px 70px 70px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;

  main {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 60px 0 80px;
  }
`;
