import { useEffect, useState } from 'react';

import { OrganizationsService } from 'app/API';
import { useAuthContext } from '@providers/AuthProvider';
import { useAPI } from '@hooks/useAPI';
import { useQueryParams } from '@hooks/useQueryParams';
import { TRepository } from 'app/types/entities/TRepository';

import { Block } from '@components/Block';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';

import { usePageContext } from '../../../providers/PageProvider';

type RepositoryItem = {
  label: string;
  value: string;
};

export const RepositoryBlock = () => {
  const [repositoryList, setRepositoryList] = useState<RepositoryItem[]>([]);
  const { form, setFormItemValue, isLoading } = usePageContext();
  const { call } = useAPI();
  const { organization } = useAuthContext();
  const searchParams = useQueryParams();

  const handleSelectChange = async (item: RepositoryItem) => {
    setFormItemValue('repositoryId', item?.value === 'null' ? null : item?.value);
  };

  useEffect(() => {
    if (!organization) {
      return;
    }
    (async () => {
      const res = await call(OrganizationsService.getOrgReposList({ oid: organization.id }));
      if (!res || !res.length) {
        setRepositoryList([
          {
            value: '',
            label: 'No case selected',
          },
        ]);
        return;
      }
      setRepositoryList(
        [
          {
            value: '',
            label: 'No case selected',
          },
        ].concat(
          res.map((item: TRepository) => ({
            value: item.id,
            label: item.name,
          })),
        ),
      );

      const queryStringRepo = searchParams.get('repo');

      if (queryStringRepo) {
        const match = res.find((r: TRepository) => r.slug == searchParams.get('repo'));
        if (match) {
          handleSelectChange({ value: match.id, label: 'repositoryId' });
        }
      } else {
        if (form.item?.repository?.id) {
          const match = res.find((r: TRepository) => r.id == form.item?.repository?.id);
          if (match) {
            handleSelectChange({ value: match.id, label: 'repositoryId' });
          }
        }
      }
    })();
  }, [organization, form.item?.repository?.id]);

  return (
    <Block title="Case" description={<p>Associate this job with a case (optional)</p>}>
      <SmallWhiteSelect
        name="repositoryId"
        label="Case name"
        value={form.item?.repositoryId}
        disabled={isLoading}
        onChange={(label, value) => handleSelectChange({ label, value })}
        options={repositoryList}
        required={false}
      />
    </Block>
  );
};
